import React from 'react';
import Helmet from 'react-helmet';
import {StaticQuery, graphql} from 'gatsby';
import {ThemeProvider} from '@material-ui/core';

import '../styles/index.css';
import Theme from '../styles/theme';

import Header from './header'
import Footer from './footer';

const Layout: React.FunctionComponent = ({children}) => (
  <StaticQuery
    query={graphql`
      query SiteIndexLayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <ThemeProvider theme={Theme}>
          <Helmet title={data.site.siteMetadata.title}
                  meta={[
                    {name: 'description', content: 'Spectral Compute'},
                    {
                      name: 'keywords',
                      content: 'GPGPU, GPU, AI, neural networks, computation'
                    },
                  ]}
                  >
            <link href="https://fonts.googleapis.com/css?family=Montserrat:400,800" rel="stylesheet"/>
            <link href="https://maxcdn.bootstrapcdn.com/bootstrap/latest/css/bootstrap.min.css" rel="stylesheet"/>
          </Helmet>
          <Header/>
          {/** Compensate for the header above inheriting `position: fixed` from `AppBar` **/}
          <div style={{marginTop: "3.75rem"}}/>
          {children}
          <Footer/>
        </ThemeProvider>
      </React.Fragment>
    )}
  />
);

export default Layout;
