import * as React from 'react';
import {AppBar, Button, Grid, Typography, makeStyles} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import {Link} from "gatsby";

import LogoImage from '../../assets/header/menu_logo_210304.svg';
import LinkedIn from '../../assets/header/LI-In-Bug.png';
import Dropdown from '../../assets/header/menu_dropdown_icon_201201.svg'
import Theme from "../styles/theme";

const useStyles = makeStyles({
  bar: {
    height: "3.75rem",
    minHeight: "48px", // Equal to `variant="dense"`, but let's put all our config here if possible
    "& img": {
      marginBottom: 0,
    }
  },
  homeIcon: {
    height: '40px',
    maxWidth: "none" // Override default
  },
  grid: {
    padding: "0 10px",
    alignItems: 'center',
    justifyContent: 'flex-end',
    [Theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  gridItem: {
    marginRight: 'clamp(1rem, 3.5vw, 4.25rem)',
  },
  menuButton: {
    padding: 0, // Override default
    minWidth: '0px', // Override default
  },
  menuIcon: {
    height: '0.75rem',
    marginRight: '0.5rem',
  },
  linkedInButton: {
    padding: 0, // Override default
    minWidth: "0px", // Override default
    position: "relative" // Allow `linkedInWhiteBackground` to be `position: absolute`
  },
  linkedInWhiteBackground: {
    backgroundColor: "#FFFFFF",
    height: "36px", // Don't poke out the transparent corners
    width: "36px", // Don't poke out the transparent corners
    position: "absolute",
    left: 0
  },
  linkedInIcon: {
    position: "relative",
    zIndex: 2, // Be on top of the white background for the lettering
    height: '2.5rem',
  },
  link: {
    "&:hover": { // This particular modification cannot be done inline
      textUnderlineOffset: "9px"
    }
  }
});

export const Header = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.bar}>
      <Toolbar disableGutters={true} className={classes.bar}>
        <Link to="/" style={{marginLeft: "10px"}}>
          <img src={LogoImage} className={classes.homeIcon} alt={"Spectral Logo"}/>
        </Link>
        <Grid container className={classes.grid}>
          <Grid item className={classes.gridItem}>
            <Link to={"/"} className={classes.link}>
              <div style={{display: "flex"}}>
                <Button disableRipple className={classes.menuButton}>
                  <img src={Dropdown} className={classes.menuIcon} alt={"Dropdown Icon"}/>
                </Button>
                <Typography variant="h4">
                  Solutions
                </Typography>
              </div>
            </Link>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Link to="/company" className={classes.link}>
              <Typography variant="h4">
                Company
              </Typography>
            </Link>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Link to="/#contact" className={classes.link}>
              <Typography variant="h4">
                Contact
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Button
              disableRipple
              href="https://www.linkedin.com/company/spectral-compute"
              className={classes.linkedInButton}
            >
              <div className={classes.linkedInWhiteBackground}/>
              <img src={LinkedIn} className={classes.linkedInIcon} alt={"LinkedIn Icon"}/>
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
};

export default Header;
