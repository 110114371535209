import {createMuiTheme} from "@material-ui/core";

// New Dark Theme (TODO: White theme?)
const Theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0E1118",
    },
    secondary: {
      main: "#E2E2E2",
    },
    contrastThreshold: 3
  },
  typography: {
    h1: {
      color: "#0067B1",
      fontWeight: "bold",
      fontSize: '9.375rem', // 150
      textTransform: "uppercase"
    },
    h2: {
      color: "#0067B1",
      fontWeight: "bold",
      fontSize: '3rem' // 48
    },
    h3: {
      color: "#0E56AB",
      fontSize: '1.875rem' // 30
    },
    h4: {
      color: "#0E56AB",
      fontSize: '1.25rem' // 20
    },
    h6: {
      color: "#1e2b5a",
      fontSize: "0.75rem",
      letterSpacing: "0.1rem"
    },
    body1: {
      color: "#0067B1",
      fontWeight: "bold",
      fontSize: '1rem' // 48
    },
    fontFamily: [
      'Montserrat'
    ].join(',')
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 526, // Smallest width at which grid item can be full size
      md: 640, // Hide header menu
      lg: 1028, // Enable 2 grid items
      xl: 1544 // Enable 3 grid items
    }
  }
});

export default Theme;
