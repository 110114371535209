import React, {useState} from 'react';
import {Link} from 'gatsby';
import {Button, Typography, Grid, TextField, makeStyles} from "@material-ui/core";

const copyrightSymbol = String.fromCharCode(169);

const useStyles = makeStyles({
  foo: {
    //backgroundColor: "#FFFFFF",
    //width: "100%",
    fontStyle: "italic",
    padding: "5px",
    fontSize: "0.75rem",
    borderStyle: "solid", border: "2px", borderColor: "#1e2b5a"
  }
});

export const Footer = () => {
  const [subscribe, setSubscribe] = useState("");

  const classes = useStyles();

  const onSubmit = (e: React.FormEvent) => {}

  return (
    <div style={{
      position: "relative",
      backgroundColor: "#dfdfdf",
      width: "100%",
      paddingLeft: "2.4vw",
      paddingRight: "2.4vw",
    }}>
      <Grid container
            justify="flex-end"
            style={{

              //marginTop: "2rem",
              //width: "100%",
              borderTopColor: "#1e2b5a",
              borderTopStyle: "solid",
              borderTop: "1px",

              //position: "absolute",
              //right: 0,
            }}
      >
        <Grid item style={{padding: "10px", width: "170px"}}>
          <Typography variant="h6" style={{fontSize: "1.5rem"}}>Solutions</Typography>
          <Typography variant="h6" style={{fontSize: "1rem"}}>ai</Typography>
          <Typography variant="h6" style={{fontSize: "1rem"}}>regex</Typography>
          <Typography variant="h6" style={{fontSize: "1rem"}}>bespoke</Typography>
        </Grid>
        <Grid item style={{padding: "10px", width: "170px"}}>
          <Typography variant="h6" style={{fontSize: "1.5rem"}}>Company</Typography>
          <Typography variant="h6" style={{fontSize: "1rem"}}>about</Typography>
          <Typography variant="h6" style={{fontSize: "1rem"}}>team</Typography>
          <Typography variant="h6" style={{fontSize: "1rem"}}>careers</Typography>
        </Grid>
        <Grid item style={{padding: "10px", width: "170px"}}>
          <Typography variant="h6" style={{fontSize: "1.5rem"}}>Contact</Typography>
          <Typography variant="h6" style={{fontSize: "1rem"}}>contact</Typography>
        </Grid>


        {/*<Grid item xs={1}/>
          <Grid item xs={7}>
          <Link to="privacy"><Button variant="text">Privacy Policy</Button></Link>
          </Grid>
          <Grid item xs={3}>
          <Typography variant="caption"><a href="mailto:hello@spectralcompute.co.uk">hello@spectralcompute.co.uk</a><br/>
          <a href="tel:+44 1392 58 1900">+44 1392 58 1900</a></Typography>
          </Grid>
          <Grid item xs={1} />

          <Grid
          container
          justify="center"
          style={{marginTop: '3rem'}}
          >
          <Typography variant="caption" align="center">
        {copyrightSymbol} 2020 Spectral Compute Ltd<br/>
          We're registered in the UK with company Number: 11448807<br/>
          You can send us snail mail at:
          483 Green Lanes, London, England, N13 4BS
          </Typography>
          </Grid>*/}
      </Grid>
      <div style={{
        //marginTop: "2vh",
        height: "47px",
        borderTop: "1px",
        borderBottom: "1px",
        borderTopStyle: "solid",
        borderBottomStyle: "solid",
        borderTopColor: "#1e2b5a",
        borderBottomColor: "#1e2b5a",
        display: "flex",
        alignItems: "center"

      }}>
        <Typography variant="h6" style={{marginLeft: "164px", marginRight: "10px"}}>newsletter</Typography>
        <form id="subscribe" onSubmit={onSubmit} style={{display: "flex", margin: 0}}>
          <TextField placeholder="Email" required margin="dense" onChange={e => setSubscribe(e.currentTarget.value)} InputProps={{disableUnderline: true, classes: {input: classes.foo}}} style={{backgroundColor: "#FFFFFF", borderStyle: "solid", border: "2px", borderColor: "#1e2b5a", margin: 0}}/>
          <Button type="submit" style={{padding: 0}}>
            <Typography variant="h6" style={{textTransform: "lowercase", marginLeft: "10px"}}>connect</Typography>
          </Button>
        </form>
      </div>
      <div style={{display: "flex", justifyContent: "space-between", height: "29px", alignItems: "center"}}>
        <Typography variant="h6">privacy & disclaimer</Typography>
        <Typography variant="h6" align="right">copyright SPECTRAL COMPUTE 2020</Typography>
      </div>
    </div>
  )
}

export default Footer;
